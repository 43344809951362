import React, { useState } from "react"
import cx from "classnames"
import { Image } from "components/image"
import { RiArrowRightUpLine, RiPlayCircleLine } from "react-icons/ri"
import { VideoModal } from "../video-modal"

import * as styles from "./release.module.css"

export const Release = ({ release }) => {
  const {
    title,
    releaseDate,
    artwork,
    tracklist,
    streamingLinks,
  } = release.node
  const [activeVideoId, setActiveVideoId] = useState("")
  const [videoModalOpen, setVideoModalOpen] = useState(false)

  const toggleVideoModal = () => {
    setVideoModalOpen(!videoModalOpen)
  }

  const handleVideoButtonClick = videoId => {
    setActiveVideoId(videoId)
    toggleVideoModal()
  }

  const formattedReleaseDate = new Date(
    `${releaseDate}T00:00:00`
  ).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })

  return (
    <div className={styles.release}>
      {artwork && artwork.asset && (
        <Image
          className={styles.artwork}
          imageId={artwork.asset._id}
          width={960}
          alt={title}
        />
      )}
      <div className={styles.details}>
        <h1 className={cx("label", styles.releaseTitle)}>{title}</h1>
        <p className={cx("body", styles.releaseDate)}>
          Released {formattedReleaseDate}
        </p>
        <ol className={cx("body", styles.tracklist)}>
          {tracklist.map(track => (
            <li className={styles.tracklistItem}>
              <div className={styles.tracklistItemInner}>
                <span className={styles.trackTitle}>{track.title}</span>
                {track.video && (
                  <button
                    onClick={() => handleVideoButtonClick(track.video.video.id)}
                    className={cx("body", styles.videoButton)}
                  >
                    Watch Video
                    <RiPlayCircleLine />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ol>
        <div className={styles.stream}>
          {streamingLinks.length > 0 && (
            <h2 className={cx("label", styles.streamLabel)}>Listen</h2>
          )}

          <ul className={styles.streamingLinks}>
            {streamingLinks &&
              streamingLinks.map(link => {
                return (
                  <li>
                    <a
                      className={styles.streamingLink}
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span class="body">{link.title}</span>
                      <RiArrowRightUpLine />
                    </a>
                  </li>
                )
              })}
          </ul>
        </div>
        <VideoModal
          videoId={activeVideoId}
          open={videoModalOpen}
          toggle={toggleVideoModal}
        />
      </div>
    </div>
  )
}
