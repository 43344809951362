import React from "react"
import cx from "classnames"

import { Modal, Slide } from "@material-ui/core/"
import { Backdrop } from "components/backdrop"
import { Release } from "components/release"

import * as styles from "./release-drawer.module.css"

export const ReleaseDrawer = ({ release, open, toggle }) => {
  return (
    <Modal
      // aria-labelledby="transition-modal-title"
      // aria-describedby="transition-modal-description"
      open={open}
      onClose={toggle}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{
        active: open,
        toggle: toggle,
      }}
    >
      <Slide
        direction="left"
        in={open}
        timeout={{
          enter: 400,
          exit: 300,
        }}
      >
        <div className={styles.drawer}>
          <button className={cx("reset", styles.closeButton)} onClick={toggle}>
            <span className="label underline-on-hover">Close X</span>
          </button>
          <div className={styles.drawerContent}>
            <Release release={release} />
          </div>
        </div>
      </Slide>
    </Modal>
  )
}
