import React from "react"
import { graphql } from "gatsby"
import Discography from "src/templates/discography"

export const query = graphql`
  fragment ReleaseQuery on SanityRelease {
    _id
    title
    slug {
      current
    }
    releaseType
    releaseDate
    tracklist: _rawTracklist(resolveReferences: { maxDepth: 4 })
    artwork: _rawArtwork(resolveReferences: { maxDepth: 10 })
    streamingLinks {
      title
      url
    }
  }
  query DiscographyQuery {
    albums: allSanityRelease(
      filter: { releaseType: { eq: "album" } }
      sort: { fields: [releaseDate], order: DESC }
    ) {
      edges {
        node {
          ...ReleaseQuery
        }
      }
    }
    eps: allSanityRelease(
      filter: { releaseType: { eq: "ep" } }
      sort: { fields: [releaseDate], order: DESC }
    ) {
      edges {
        node {
          ...ReleaseQuery
        }
      }
    }
    singles: allSanityRelease(
      filter: { releaseType: { eq: "single" } }
      sort: { fields: [releaseDate], order: DESC }
    ) {
      edges {
        node {
          ...ReleaseQuery
        }
      }
    }
  }
`

const DiscographyPage = ({ data, location }) => {
  return <Discography location={location} pageContext={data} />
}

export default DiscographyPage
