import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { Image } from "components/image"

import styles from "./discography-section.module.css"

export const DiscographySection = ({
  label,
  category,
  activeRelease,
  setActiveRelease,
  releaseDrawerOpen,
  toggleReleaseDrawer,
}) => {
  const releases = category.edges
  const handleReleaseClick = release => {
    setActiveRelease(release)
    toggleReleaseDrawer()
  }
  return (
    <div className={styles.section}>
      <h2 className={cx("label", styles.categoryLabel)}>{label}</h2>
      <div className={styles.grid}>
        {releases.map(release => {
          const { title, releaseDate, artwork, _id } = release.node
          const formattedReleaseDate = new Date(
            `${releaseDate}T00:00:00`
          ).toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })

          return (
            <div className={styles.gridItem} key={_id}>
              <button
                className={cx("reset", styles.releaseButton)}
                onClick={() => handleReleaseClick(release)}
              >
                {artwork && artwork.asset && (
                  <Image
                    className={styles.artwork}
                    imageId={artwork.asset._id}
                    width={960}
                    alt={title}
                  />
                )}
                <div className={styles.overlay}>
                  <p className={cx("label", styles.releaseTitle)}>{title}</p>
                  <p className={cx("body", styles.releaseDate)}>
                    {formattedReleaseDate}
                  </p>
                </div>
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}
