import React, { useState } from "react"
import { RevealOnMount } from "components/reveal-on-mount"
import SEO from "components/seo"
import { DiscographySection } from "components/discography-section"
import { ReleaseDrawer } from "components/release-drawer"

const Discography = ({ location, pageContext }) => {
  const { albums, eps, singles } = pageContext
  const [activeRelease, setActiveRelease] = useState()
  const [releaseDrawerOpen, setReleaseDrawerOpen] = useState(false)

  const toggleReleaseDrawer = () => {
    setReleaseDrawerOpen(!releaseDrawerOpen)
  }

  return (
    <RevealOnMount location={location}>
      <SEO title="Discography" />
      <DiscographySection
        label="Albums"
        category={albums}
        activeRelease={activeRelease}
        setActiveRelease={setActiveRelease}
        releaseDrawerOpen={releaseDrawerOpen}
        toggleReleaseDrawer={toggleReleaseDrawer}
      />
      <DiscographySection
        label="EPs"
        category={eps}
        activeRelease={activeRelease}
        setActiveRelease={setActiveRelease}
        releaseDrawerOpen={releaseDrawerOpen}
        toggleReleaseDrawer={toggleReleaseDrawer}
      />
      <DiscographySection
        label="Singles"
        category={singles}
        activeRelease={activeRelease}
        setActiveRelease={setActiveRelease}
        releaseDrawerOpen={releaseDrawerOpen}
        toggleReleaseDrawer={toggleReleaseDrawer}
      />
      <ReleaseDrawer
        release={activeRelease}
        open={releaseDrawerOpen}
        toggle={toggleReleaseDrawer}
      />
    </RevealOnMount>
  )
}

export default Discography
